import React from "react";
import { axios } from "../request";

export const useAppConfig = () => {
  const [config, setConfig] = React.useState(null);

  React.useEffect(() => {
    axios
      .get("/config")
      .then(({ data }) => {
        if (!Object.keys(data).length) {
          throw new Error('Something went wrong [1]')
        }
        setConfig(data);
      })
      .catch((err) => alert(err.message));
  }, []);

  return { config };
};
