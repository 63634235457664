import React, { useEffect, useRef, useState } from "react";
import "./Chat.scss";
import { axios } from "./request";
import { Config } from "./Config";
import { useAppConfig } from "./hooks/useAppConfig";
import Modal from "react-modal";
Modal.defaultStyles.overlay.backgroundColor = "rgba(0,0,0,0.25)";

export const Chat = () => {
  const chatRef = useRef();
  const [sessionId, setSessionId] = useState();
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);

  const { config } = useAppConfig();

  const [modalParams, setModalParams] = useState(null);

  const [values, setValues] = useState({
    prompt: "",
    model: 'google',
    experiment: "",
    temperature: 0.9,
    top_k: 40,
    top_p: 0.95,
  });

  const pushMessage = (message) => {
    setMessages((_messages) => [..._messages, message]);

    if (chatRef.current) {
      const q = chatRef.current;

      setTimeout(() => {
        q.scrollTo(0, q.scrollHeight);
      }, 50);
    }
  };

  const handleChangeConfig = (field, value) => {
    setValues((c) => ({
      ...c,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (config && config.length) {
      handleChangeConfig("experiment", config[0]);
    }
  }, [config]);

  const sendMessage = async () => {
    let _sessionId = sessionId;

    if (!sessionId) {
      _sessionId = Math.random().toString(36).substring(7);
      localStorage.setItem("sessionId", _sessionId);
      setSessionId(_sessionId);
    }

    const text = input;
    setInput("");

    pushMessage({
      type: "out",
      message: text,
      date: new Date(),
    });

    try {
      const { data } = await axios({
        method: "POST",
        url: "/chat",
        data: {
          sessionId: _sessionId,
          text,
          ...values,
        },
      });

      if (data.success) {
        data.payload.forEach(pushMessage);
      } else {
        throw new Error(data.message ?? "Something went wrong.");
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className="Container">
      <div className="Wrapper">
        {sessionId ? (
          <ul className="Chat" ref={chatRef}>
            {messages.map(({ type, message, date, params }, index) => {
              return (
                <li key={index} className={`Message Message-${type}`}>
                  {type === "in" ? (
                    <img src="./logo.svg" className="Logo" alt="" />
                  ) : (
                    <div className="Avatar">You</div>
                  )}

                  <div className="Message-Text">
                    {message}

                    {params && (
                      <div>
                        <button onClick={() => setModalParams(params)}>
                          View context
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <p>Type your question below to start a new session</p>
        )}

        <div className="Textarea">
          <div className="Avatar">You</div>
          <textarea
            className="Textarea"
            placeholder="Type your message..."
            rows="1"
            value={input}
            onChange={(e) => setInput(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                sendMessage();
              }
            }}
          ></textarea>
        </div>
      </div>

      {config && (
        <>
          <Config
            config={config}
            values={values}
            onChange={handleChangeConfig}
          />
          <Modal isOpen={!!modalParams} style={{content: {padding: 24, width: '1024px', margin: 'auto'}}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <h2 style={{margin: 0, fontSize: 24, marginBottom: 12}}>Context parameters</h2>

              <button onClick={() => setModalParams(null)} style={{fontSize: 16}}>Close</button>
            </div>

            {modalParams && Object.entries(modalParams).map(([key, value]) => (
              <pre style={{fontSize: 16, marginTop: 8, marginBottom: 0}}>
                <strong>{key}:</strong> {value || '-'}
              </pre>
            ))}
          </Modal>
        </>
      )}
    </div>
  );
};
