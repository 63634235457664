import React from "react";
import "./Config.scss";

export const Config = ({ config, values, onChange } = {}) => {
  return (
    <div className="Config">
      <h1>Hadley AI Config</h1>

      <label htmlFor="prompt">Prompt</label>
      <textarea
        name="prompt"
        id="prompt"
        value={values.prompt}
        onChange={(e) => onChange("prompt", e.currentTarget.value)}
        placeholder="Enter message to use in the AI prompt.."
      ></textarea>

      <label htmlFor="exp">Model</label>
      <select
        id="exp"
        value={values.model}
        onChange={(e) => onChange("model", e.target.value)}
      >
        <option value="google">Google (default)</option>
        <option value="openai">ChatGPT OpenAI</option>
      </select>

      <label htmlFor="exp">Experiment</label>
      <select
        id="exp"
        value={values.experiment}
        onChange={(e) => onChange("experiment", e.target.value)}
      >
        {config.map((value) => (
          <option value={value}>{value}</option>
        ))}
      </select>

      <label htmlFor="temp">Temperature: {values.temperature}</label>
      <div>
        <input
          value={values.temperature}
          type="range"
          id="temp"
          min={0}
          max={1}
          step={0.01}
          onChange={(e) => onChange("temperature", parseFloat(e.target.value))}
        />
      </div>
      <label htmlFor="temp">Top K: {values.top_k}</label>
      <div>
        <input
          value={values.top_k}
          type="range"
          id="temp"
          min={0}
          max={40}
          step={1}
          onChange={(e) => onChange("top_k", parseInt(e.target.value))}
        />
      </div>
      <label htmlFor="temp">Top P: {values.top_p}</label>
      <div>
        <input
          value={values.top_p}
          type="range"
          id="temp"
          min={0}
          max={1}
          step={0.01}
          onChange={(e) => onChange("top_p", parseFloat(e.target.value))}
        />
      </div>
    </div>
  );
};
